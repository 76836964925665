<template>
	<div class="filelist">
		<div class="fileitem" v-for="item in datas" :key="item.url">
			<div class="icon"><i class="el-icon-document"></i></div>
			<div class="cont">{{item.name}}</div>
			<div class="delicon" @click="datas.splice(index,1)"><i class="el-icon-close"></i></div>
		</div>
	</div>
</template>

<script>
	export default {
		name:'filelist',
		data() {
			return {
				
			};
		},
		props:{			
			datas:{
				type:Array
			}
		},
		watch:{
			// datas(){
			// 	this.$emit('change',this.datas);
			// }
		},
		methods: {
			
		}
	};
</script>

<style lang="less" scoped scoped>
	.filelist{
		.fileitem{display: flex;align-items: center;border-radius: 5px;line-height: 1.2;padding: 5px;margin-top: 5px;cursor: pointer;
			.icon{margin-right: 10px;}
			.cont{flex:1;overflow:hidden; text-overflow: ellipsis; white-space: nowrap; word-wrap: break-word; word-break: break-all;}
			.delbtn{color:#999;margin-left: 20px;cursor: pointer;}
			&:hover{background-color: #f8f8f8;}
		}
	}
</style>