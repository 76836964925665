<template>
	<div class="work2">
		<div class="home_center">
			<div class="">
				<div class="center_left">
					<div class="left_top">
						<div class="top_name">
							<!-- 外部工作 -->{{$t('work.WBGZ')}}
						</div>
						<router-link to="/work" class="tablink flex-center">
							<img src="@/assets/img/qiehuan.png"> <!-- 切换到内部工作管理 -->{{$t('QHDNBGZGL')}}
						</router-link>
						
						<div class="flex-item"></div>
						<!-- <el-input v-model="input" class="top_input" placeholder="请输入内容"></el-input> -->
						<el-input :placeholder="$t('USEKEYWORD')" class="top_input"  v-model="listData.job_name" clearable @keyup.enter.native="" @input="search"></el-input>
						<div class="top_btn" v-if="status==3">
							<el-button :class="listData.apply_status==0?'btn1':'btn2'" :type="listData.apply_status==0?'primary':''"  @click="listData.apply_status=0"><!-- 申请中 -->{{$t('work.SQZ')}}</el-button>
							<el-button :class="listData.apply_status==2?'btn1':'btn2'" :type="listData.apply_status==2?'primary':''"  @click="listData.apply_status=2"><!-- 已拒绝 -->{{$t('work.YJJ')}}</el-button>
						</div>
					</div>
					<div class="left_box">
						<div class="left_left">
							<el-button  @click="tab(1)" :class="{btn1:status==1,btn2:status!=1}" type="primary"><!-- 收藏的工作 -->{{$t('work.SCDGZ')}}<template v-if="tongji.collectCount>0">（{{tongji.collectCount}}）</template></el-button>
							
							<el-button @click="tab(3)" :class="{btn1:status==3,btn2:status!=3}" type="primary"><!-- 申请中的工作 -->{{$t('work.SQZDGZ')}}<template v-if="tongji.applyCount>0">（{{tongji.applyCount}}）</template></el-button>
							<el-button @click="tab(2)" :class="{btn1:status==2,btn2:status!=2}" type="primary"><!-- 进行中的工作 -->{{$t('work.JXZDGZ')}}<template v-if="tongji.progressCount>0">（{{tongji.progressCount}}）</template></el-button>
							<el-button @click="tab(4)" :class="{btn1:status==4,btn2:status!=4}" type="primary"><!-- 往期工作 -->{{$t('work.WQGZ')}}<template v-if="tongji.historyCount>0">（{{tongji.historyCount}}）</template></el-button>
							
						</div>
						<div class="left_center" v-loading="loading"  element-loading-background="rgba(0, 0, 0, 0.8)">
							<div class="center_box">
								<!-- 收藏工作 -->
								<template v-if="status==1">
									<div class="box_list" v-for="(item,index) in list" :key="item.job_collect_id">
										<img :src="item.employer_avatar_url" class="list_img" alt="">
										<div class="list_center">
											<div class="center_title">
												{{item.employer_name}}
												<div class="title_card" v-if="item.employer_role==1">
													<!-- 企 -->{{$t('QI')}}
												</div>
											</div>
											<div class="center_name">
												<div class="name">
													<div class="name_card" v-if="item.job_belong_method==1">
														<!-- 中介 -->{{$t('ZJ')}}
													</div>
													{{item.job_name}}
												</div>
												<div class="time">
													<!-- 工作日期 -->{{$t('work.QZRQ')}}：{{$util.rTime(item.create_time).split(' ')[0]}}
												</div>
											</div>
											<div class="center_msg">
												{{item.job_desc}}
											</div>
										</div>
										<div class="box_right">
											<div class="right_time">
												<!-- 截止日期 -->{{$t('job.JZRQ')}}：{{item.job_end_time}}
											</div>
											<el-button class="btn2" type="primary" @click="cancelfav(item.job_id)"><!-- 取消收藏 -->{{$t('work.QXSC')}}</el-button>
											<el-button v-if="status==1" class="btn1" type="primary" @click="applyJob(item.job_id)"><!-- 申请工作 -->{{$t('job_detail.SQGZ')}}</el-button>
										</div>
									
									</div>
								</template>
								<!-- 进行中的工作 -->
								<template  v-if="status==2">
									<div class="box_list" v-for="(item,index) in list" :key="item.job_manage_id">
										<img :src="item.employer_avatar_url" class="list_img" alt="">
										<div class="list_center">
											<div class="flex-center">
												<div class="flex-item">
													<div class="center_title">
														{{item.employer_name}}
														<div class="title_card" v-if="item.employer_role==1">
															<!-- 企 -->{{$t('QI')}}
														</div>
													</div>
													<div class="center_name">
														<div class="name">
															<div class="name_card" v-if="item.job_belong_method==1">
																<!-- 中介 -->{{$t('ZJ')}}
															</div>
															{{item.job_name}}
														</div>
														<div class="time">
															<!-- 工作日期 -->{{$t('work.QZRQ')}}：{{$util.rTime(item.contract_date)}}
														</div>
													</div>
												</div>
												<div class="wcright" v-if="item.job_progress==1&&item.job_manage_status!=0">
													<div class="wcbtn"  @click="confirmJob(item.job_id,item.job_manage_id)"><img src="@/assets/img/icon_gou2.png"><!-- 工作完成 -->{{$t('GZWC')}}</div>
													<!-- 驳回文字 -->
													<div v-if="item.job_manage_status==1" style="font-size: 12px;margin-top:5px;color:#ffe72e">{{$t('QBJHWC')}}</div>
													<!-- 提交文字 -->
													<div style="font-size: 12px;margin-top:5px;color:#f00" v-if="item.job_manage_status==3">{{$t('QBJHWC2')}}</div>
												</div>
												<div class="wcright" v-if="item.job_progress==1&&item.job_manage_status==0">
													<div class="wcbtn active" ><img src="@/assets/img/icon_gou3.png"><!-- 工作完成 -->{{$t('GZWC')}}</div>
													
													
												</div>
											</div>
											<div class="list_plan">
												<div class="plan_menu">
													<div class="status active">
														<div class="date">{{$util.rTime(item.contract_date).split(' ')[0]}}</div>
														<img src="@/assets/img/icon_gou.png">
													</div>
													<div class="menu_name"><!-- 合同签订 -->{{$t('work.HTQD')}}</div>
												</div>
												<div class="plan_menu">
													<div class="status active" v-if="item.job_progress>0">
														<div class="date">{{$util.rTime(item.submit_date).split(' ')[0]}}</div>
														<img src="@/assets/img/icon_gou.png">
													</div>
													<div class="status" v-else><img src="@/assets/img/icon_more.png"></div>
													<div class="menu_name"><!-- 交付工作 -->{{$t('work.JFGZ')}}</div>
												</div>
												<div class="plan_menu">
													<div class="status active" v-if="item.job_progress>1">
														<div class="date">{{$util.rTime(item.close_date).split(' ')[0]}}</div>
														<img src="@/assets/img/icon_gou.png">
													</div>
													<div class="status" v-else><img src="@/assets/img/icon_more.png"></div>
													<div class="menu_name"><!-- 确认完成 -->{{$t('QRWC')}}</div>
												</div>
												<div class="plan_menu">
													<div class="status active" v-if="item.job_progress>2">
														<div class="date">{{$util.rTime(item.comment_date).split(' ')[0]}}</div>
														<img src="@/assets/img/icon_gou.png">
													</div>
													<div class="status" v-else><img src="@/assets/img/icon_more.png"></div>
													<div class="menu_name"><!-- 工作点评 -->{{$t('work.GZDP')}}</div>
												</div>
												
												
											</div>
										</div>
										<div class="box_right">
											<el-button class="btn1" type="primary" @click="senmsg(item.employer_id)"><!-- 发消息 -->{{$t('home.FDXX')}}</el-button>
											<div class="jfbtn">
												<div class="btn" @click="openJf(item.job_id,item.job_manage_id)">
													<div><img src="@/assets/img/icon_wc.png"></div>
													<div class="name"><!-- 工作交付 -->{{$t('work.GZXZ')}}</div>
												</div>
												<div class="btn" @click="openJfls(item.job_manage_id)">
													<div><img src="@/assets/img/icon_wwc.png"></div>
													<div class="name"><!-- 历史交付 -->{{$t('work.LSJF')}}</div>
												</div>
											</div>
											<!-- <el-button class="btn2" type="primary">工作交付</el-button>
											<el-button class="btn2" type="primary">历史交付</el-button> -->
											<!-- <div class="right_time">
												截止日期：2022.06.05
											</div> -->
										</div>
									
									</div>
								</template>
								<!-- 申请中的工作 -->
								<template  v-if="status==3">
									<div class="box_list" v-for="(item,index) in list" :key="item.job_apply_id">
										<img :src="item.employer_avatar_url" class="list_img" alt="">
										<div class="list_center">
											<div class="flex-start">
												<div class="flex-item">
													<div class="center_title">
														{{item.employer_name}}
														<div class="title_card" v-if="item.employer_role==1">
															<!-- 企 -->{{$t('QI')}}
														</div>
													</div>
													<div class="center_name">
														<div class="name">
															<div class="name_card" v-if="item.job_belong_method==1">
																<!-- 中介 -->{{$t('ZJ')}}
															</div>
															{{item.job_name}}
														</div>
														<div class="time">
															<!-- 工作日期 -->{{$t('work.QZRQ')}}：{{$util.rTime(item.create_time)}}
														</div>
													</div>
												</div>
												<div class="right_time" v-if="item.apply_status==2"><!-- 拒绝时间 -->{{$t('JJSJ')}}：{{item.update_time}}</div>
												<el-button class="btn1" @click="senmsg(item.employer_id)" type="primary" style="margin: 0 0 0 15px;" v-else><!-- 发消息 -->{{$t('home.FDXX')}}</el-button>
												
											</div>
											
											
											<div class="center_msg">
												{{item.job_desc}}
											</div>
										</div>
										
									
									</div>
								</template>
								<!-- 往期工作 -->
								<template  v-if="status==4">
									<div class="box_list" style="align-items: flex-start;" v-for="(item,index) in list" :key="item.job_apply_id">
										<img :src="item.employer_avatar_url" class="list_img" alt="">
										<div class="list_center">
											<div class="flex-start">
												<div class="flex-item">
													<div class="center_title">
														{{item.employer_name}}
														<div class="title_card" v-if="item.employer_role==1">
															<!-- 企 -->{{$t('QI')}}
														</div>
													</div>
													<div class="center_name">
														<div class="name">
															<div class="name_card" v-if="item.job_belong_method==1">
																<!-- 中介 -->{{$t('ZJ')}}
															</div>
															{{item.job_name}}
														</div>
														<div class="time">
															<!-- 完成时间 -->{{$t('work.WJSJ')}}：{{$util.rTime(item.close_date)}}
														</div>
													</div>
												</div>
												<el-button class="btn1" @click="senmsg(item.employer_id)" type="primary" style="margin: 0 0 0 15px;"><!-- 发消息 -->{{$t('home.FDXX')}}</el-button>
											</div>
											
											
											<div class="box_form">
												<div class="form_time">
													<div class="time">
														<!-- 日程 -->{{$t('RC')}}：
														<el-rate :disabled="item.employer_comment_status==1" void-color="#666" disabled-void-color="#666" :colors="colors" v-model="item.employer_schedule_point"></el-rate>
														<!-- <span style="color:#FFA034">{{item.employer_schedule_point}}{{$t('FEN')}}</span> -->
													</div>
													<div class="time">
														<!-- 表达 -->{{$t('BD')}}：
														<el-rate :disabled="item.employer_comment_status==1" void-color="#666" disabled-void-color="#666" :colors="colors" v-model="item.employer_talk_point"></el-rate>
														<!-- <span style="color:#FFA034">{{item.employer_talk_point}}{{$t('FEN')}}</span> -->
													</div>
												</div>
												<div class="form_time">
													<div class="time">
														<!-- 合伙企业 -->{{$t('HHQY')}}：
														<el-rate :disabled="item.employer_comment_status==1" void-color="#666" disabled-void-color="#666" :colors="colors" v-model="item.employer_partner_point"></el-rate>
														<!-- <span style="color:#FFA034">{{item.employer_partner_point}}{{$t('FEN')}}</span> -->
													</div>													
												</div>
												
												
												<div class="form_msg">
													<template v-if="item.employer_comment_status==1">{{item.employer_content}}</template>
													<el-input
													v-else
													  type="textarea"
													  :rows="3"
													  :placeholder="$t('QSR')"
													  v-model="item.employer_content">
													</el-input>
												</div>
												<div class="flex-center">
													<div class="flex-item"></div>
													<el-button class="last_btn" v-if="item.employer_comment_status==0" @click="pingfen(item)">
														<!-- 确认 -->{{$t('CONFIRM')}}
													</el-button>
												</div>
											</div>
										</div>
										
									
									</div>
								</template>
								
							</div>
						</div>
					</div>
					
				</div>
				
			</div>
			
			<!-- 消息列表 -->
			<Homeright></Homeright>
		</div>
		<!-- 回复弹窗 -->
		<!-- <Reply></Reply> -->
		<!-- 全部评论 -->
		<!-- <Pinlun></Pinlun> -->
		<el-dialog :title="$t('work.JFGZ')"  :visible.sync="jfdialog" :close-on-click-modal="false" width="600px" >
		  <el-form label-width="100px">			 
			  <el-form-item :label="$t('job_detail.WJSC')" prop="download_method">
			  	<filelist :datas="jform.submit_files"></filelist>
				<el-upload style="margin-top: 10px;"
				  class="upload-demo"
				  :action="$config.uploadUrl"
				  :show-file-list="false"
				  :on-success="uploadFile"
				  
				  
				  multiple>
				  <el-button size="small" type="primary"><!-- 点击上传 -->{{$t('job.DJSC')}}</el-button>
				  
				</el-upload>
			  </el-form-item>
			  <el-form-item :label="$t('job_detail.JFSM')">
				<el-input
				  type="textarea"
				  :rows="3"
				  :placeholder="$t('QSR')"
				  v-model="jform.submit_description">
				</el-input>
			  </el-form-item>
		  </el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="jfdialog = false"><!-- 取 消 -->{{$t('CANCEL')}}</el-button>
		    <el-button type="primary" @click="jiaofu"><!-- 确 定 -->{{$t('CONFIRM')}}</el-button>
		  </span>
		</el-dialog>
		<el-dialog :title="$t('work.LSJF')"  :visible.sync="historyDialog" width="800px" >
			<div class="jfgroup" v-for="item in jflist" :key="item.job_submit_id">
				<div class="row" >
					<div class="name" style="padding-top: 12px;"><!-- 文件上传 -->{{$t('job_detail.WJSC')}}</div>
					<div class="flex-item">
						<div class="flex-center filerow" v-for="file in item.submit_files" :key="file.url">
							<div class="flex-item filename">
								{{file.name}}
							</div>
							<div style="margin-left: 10px;">
								<el-button type="primary"  @click="$util.downFileUrl(file.url,file.name)"><!-- 点击下载 -->{{$t('job_detail.DJXZ')}}</el-button>
							</div>
						</div>
						
					</div>
				</div>
				<div class="row">
					<div class="name"><!-- 交付说明 -->{{$t('job_detail.JFSM')}}</div>
					<div class="flex-item">
						{{item.submit_description}}
					</div>
				</div>
				<div class="row">
					<div class="name"><!-- 交付时间 -->{{$t('job_detail.JFSJ')}}</div>
					<div class="flex-item">
						{{item.submit_time}}
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import 'quill/dist/quill.core.css';
	import 'quill/dist/quill.snow.css';
	import 'quill/dist/quill.bubble.css';
	import { quillEditor } from 'vue-quill-editor';
	import Homeright from '../../components/home/home_right.vue';
	import scroll from '@/utils/scroll.js';
	import {debounce} from '@/utils/debounce.js';
	import filelist from '@/components/filelist.vue';
	export default {
		components: {
			Homeright,
			quillEditor,filelist
		},
		computed: {
		    editor() {
		      return this.$refs.myQuillEditor.quill;
		    }
		  },
		data() {
			return {
				historyDialog:false,
				jfdialog:false,
				jflist:[],
				status:1,
				listData:{
					job_name:'',
					apply_status:0,
					pageSize:15,					
				},
				currentPage:1,
				isLast:0,
				list:[],
				loading:false,
				jform:{
					job_id:'' ,
					job_manage_id: '',
					submit_files: [],
					submit_description: ''
				},
				colors:['#567BB6', '#567BB6', '#567BB6'],
				tongji:{
					applyCount:0,
					collectCount:0,
					progressCount:0
				}
				
				
			};
		},
		created() {
			if(this.$route.query.status){
				this.status=this.$route.query.status;
			}
			if(this.$route.params.status){
				this.status=this.$route.params.status;
			}
			this.getTotal();
			this.getList();
			scroll.scrollToBottom(this.getList);
		},
		watch:{
			'listData.apply_status':{
				handler:function(){
					this.getList('reload');
				}
			},
			'$route.query.status':{
				handler:function(n,o){
					console.log('路由变化',n);
					this.status=n||1;
					this.getList('reload');
				}
				
			}
		},
		methods:{
			//获取统计数据
			async getTotal(){
				let res=await this.$request.get('/api/custJobManage/manageJobStat');
				this.tongji=res.data;
			},
			senmsg(id){
				this.$emit('chat',{user_id:id});
			},
			//评分
			async pingfen(item){
				if(item.employer_schedule_point==0||item.employer_partner_point==0||item.employer_talk_point==0){
					
					await this.$confirm(this.$t('YXTS'), this.$t('TISHI'), {
					  confirmButtonText: this.$t('CONFIRM'),
					  cancelButtonText: this.$t('CANCEL'),
					  type: 'warning'
					});
					
				}
				let data={
					job_manage_id:item.job_manage_id,
					job_comment_id:item.job_comment_id,
					employer_schedule_point:item.employer_schedule_point,
					employer_partner_point:item.employer_partner_point,
					employer_talk_point:item.employer_talk_point,
					employer_content:item.employer_content,
					
				};
				console.log(data);
				
				let res=await this.$request.post('/api/custJobManage/commentJob',data);
				this.getList('reload');
				
			},
			//申请完成工作
			async confirmJob(job_id,job_manage_id){
				this.$confirm(this.$t('QRWCWORK'), this.$t('TISHI'), {
				  confirmButtonText: this.$t('CONFIRM'),
				  cancelButtonText:this.$t('CANCEL'),
				  type: 'warning'
				}).then(async () => {
					let res=await this.$request.post('/api/custJobManage/confirmJob',{job_id:job_id,job_manage_id:job_manage_id});
				  this.$message({
					type: 'success',
					message: res.message
				  });
				  this.getList('reload');
				}).catch(() => {
				           
				});
				
			},
			//打开交付历史
			async openJfls(job_manage_id){				
				let res=await this.$request.get('/api/custJobManage/historySubmit',{job_manage_id:job_manage_id});
				this.jflist=res.data;
				this.historyDialog=true;
			},
			//打开交付
			openJf(job_id,job_manage_id){				
				this.jform={
					job_id:job_id ,
					job_manage_id:job_manage_id,
					submit_files: [],
					submit_description: ''
				};
				this.jfdialog=true;
			},
			//交付
			async jiaofu(){				
				let res=await this.$request.post('/api/custJobManage/submitJob',this.jform);
				this.$message({
					message:res.message,
					type:'success'
				});
				this.jfdialog=false;
				this.getList('reload');
			},
			//上传文件
			uploadFile(response, file, fileList){				
				let files=JSON.parse(JSON.stringify(this.jform.submit_files));
				files.push({name:file.name,url:response.data.Location});
				this.jform.submit_files=files;
				
			},
			//申请工作
			async applyJob(id){
				let res=await this.$request.get('/api/custJobManage/applyJob',{job_id:id});
				this.$message({
					message:res.message,
					type:'success'
				});
				this.getList('reload');		
			},
			//取消收藏
			async cancelfav(id){
				let res=await this.$request.get('/api/custJobManage/collectJob',{job_id:id});				
				this.getList('reload');		
			},
			//切换工作列表状态
			tab(n){
				this.status=n;
				this.$router.push({path:'/work2',query:{status:n}});
				//this.getList('reload');
			},
			search:debounce(function(e){
				console.log(e);
				this.getList('reload');
			}),
			//获取工作
			  async getList(t){
				 
				if(t=='reload'){
					this.currentPage=1;
					this.isLast=0;		  	
				}
				if(this.isLast==1||this.loading){
					return;
				}
				let urls={1:'/api/custJobManage/collectPage',2:'/api/custJobManage/progressPage',3:'/api/custJobManage/applyPage',4:'/api/custJobManage/historyPage'};
				let data=JSON.parse(JSON.stringify(this.listData));
				data.currentPage=this.currentPage;
				this.loading=true;
				let res=await this.$request.post(urls[this.status],data);
				this.loading=false;
				if(t=='reload'){
					this.list=res.data.records;
				}else{
					if(res.data.records.length>0){
						this.list=[...this.list,...res.data.records];
					}
				}
				
				if(this.currentPage>=res.data.totalPage){
					this.isLast=1;
				}else{
					this.currentPage+=1;
				}
				
			  }
			
		}
	};
</script>

<style lang="less" scoped>
	.jfgroup{background-color: #f8f8f8;border-radius: 10px;padding: 15px 25px;margin: 15px 0;
		&:first-child{margin-top: 0;}
		.row{display: flex;align-items: flex-start;margin-top: 25px;
			&:first-child{margin-top: 0;}
			.name{margin-right: 15px;color: #000;}
		}
		.filerow{margin-top: 10px;
			&:first-child{margin-top: 0;}
		}
		.filename{display: flex;flex-wrap: wrap;box-sizing: border-box; min-height: 40px;padding: 10px;background-color: #fff;border-radius: 4px;line-height: 1.3;}
		.downbtn{background-color: #567BB6;border-color: #567BB6;}
	}
	.work2 {
		min-height: 100%;
		background-color: #212121;
		.home_center{
			display: flex;
			justify-content: flex-end;
		}
		.center_left {
			color: #fff;
			width: 1190px;
			margin-right: 25px;
			.left_top{
				width: 1190px;
				display: flex;
				align-items: center;
				
				background-color: #000000;
				padding: 15px;
				box-sizing: border-box;
				.top_name{
					font-size: 21px;
					font-weight: bold;
					margin-right: 50px;
				}
				.tablink{color:#567BB6;font-size: 14px;}
				.top_input{
					width: 530px;
				}
				.el-input__inner{
					background-color: #000000;
					height: 34px;
				}
				.top_btn{
					margin-left: 50px;
					.btn2{
						background-color: #000000;
						color: #979797;
						border: 1px solid #979797;
					}
					.btn1{
						background-color: #567BB6;
						
						border-color: #567BB6;
					}
					.top_el_btn{
						background-color: #567BB6;
						border: none;
					}
				}
			}
			.left_box{
				margin-top: 25px;
				display: flex;
				.left_left{
					display: flex;
					flex-direction: column;
					width: 286px;
					margin-right: 25px;
					.mt20{
						margin-top: 20px;
					}
					.btn1{
						width: 286px;
						background-color: #567BB6;
						border: none;border: 1px solid #567BB6;
					}
					.btn2:hover{
						width: 286px;
						background-color: #567BB6;
						border: none;border: 1px solid #567BB6;
					}
					.btn2{
						width: 286px;
						background-color: #212121;
						border: 1px solid #567BB6;
						color: #fff;
					}
					.el-button{
						margin-left: 0;
						margin-bottom: 12px;
					}
				}
				.left_center{
					width: 878px;
					.center_box{
						.box_list{
							padding: 20px;
							display: flex;
							align-items: flex-start;
							background-color: #000000;
							margin-bottom: 20px;
							border-radius: 7px;
							.list_img{
								width: 118px;
								height: 118px;
								margin-right: 15px;
								border-radius: 5px;
								 object-fit: cover;
							}
							.wcright{display: flex;flex-direction: column;align-items: flex-end;margin-left: 10px;}
							.wcbtn{display: inline-flex;align-items: center;justify-content: center; padding:0 10px; height: 30px;border:1px solid #567BB6;border-radius:10px;box-sizing: border-box;text-align: center;font-size: 12px;cursor: pointer;
								img{vertical-align: middle;margin-right: 3px;}
								&.active{border-color:#50B670;color:#50B670; cursor: not-allowed;}
							}
							.box_right{margin-left: 15px; border-left:1px solid #444;padding-left: 15px;
								font-size: 14px;
								
								
							}
							.right_time{
								margin-left: 15px;
								color:#567BB6;
								font-size: 14px;
							}
							.btn1{
								width: 134px;
								height: 34px;
								background-color: #567BB6;
								border: none;
								margin-left: 0;
								margin-top: 9px;
								display: flex;
								align-items: center;
								justify-content: center;
							}
							.btn2{
								width: 134px;
								height: 34px;
								margin-left: 0;
								background-color: #212121;
								border: 1px solid #567BB6;
								color: #fff;
								margin-top: 9px;
								display: flex;
								align-items: center;
								justify-content: center;
							}
							.jfbtn{display: flex;justify-content: center;margin-top: 20px;
								.btn{border-right: 1px solid #555;display: flex;flex-direction: column;align-items: center;font-size: 12px;flex:1;
									&:last-child{border-right: none;}
									.name{margin-top: 10px;}
								}
							}
							.list_center{
								line-height: 1.3;
								flex:1;
								
								.center_title{
									display: flex;
									margin-bottom: 10px;
									.title_card{
										//width: 15px;
										height: 15px;
										background-color: #B65664;
										text-align: center;
										line-height: 15px;
										font-size: 12px;
										margin-left: 10px;
										padding:0 3px;
									}
								}
								.box_form{
									.form_list{
										display: flex;
										align-items: center;
										margin-bottom: 10px;
										.list_name{
											width: 119px;
											margin-right: 10px;
											text-align: right;
											font-size: 14px;
										}
										.el-input{
											width: 429px;
											margin-right: 10px;
										}
										.el-input__inner{
											
											color: #fff;
											font-size: 12px;
											background-color: #000;
											border: 1px solid #567BB6;
										}
										.list_center{
											width: 429px;
											margin-right: 10px;
											border-bottom: 1px solid #979797;
											color: #fff;
											line-height: 35px;
											font-size: 12px;
										}
										.el-button{
											background-color: #567BB6;
											border: 1px solid #567BB6;
											color: #fff;
										}
									}
									.form_time{
										display: flex;
										align-items: center;
										justify-content: space-between;
										margin-bottom: 10px;
										.time{
											width: 50%;
											
											font-size: 12px;
											display: flex;
											align-items: center;
										}
										.el-input{
											margin-left: 10px;
											.el-input__inner{
												border: none;
												background-color: #000;
												color: #fff;
											}
										}
									}
									.form_msg{
										color: #fff;
										font-size: 12px;
										line-height: 1.3;
										margin-top: 20px;
										background-color: #16171C;padding: 10px;border-radius: 6px;
										.el-textarea__inner{padding: 0; border: none;color: #fff;background: none;}
									}
									.last_btn{
										margin-top: 20px;
										background-color: #567BB6;
										border: 1px solid #567BB6;
										color: #fff;
									}
								}
								.center_name{
									display: flex;
									align-items: center;
									margin-bottom: 10px;
									.name{
										display: flex;
										align-items: center;
										font-size: 12px;
										margin-right: 10px;
										.name_card{
											width: 31px;
											height: 14px;
											background: #567BB6;
											border-radius: 2px;
											font-size: 10px;
											text-align: center;
											line-height: 14px;
											margin-right: 10px;
										}
									}
									.time{
										font-size: 12px;
									}
								}
								.list_plan{
									display: flex;
									align-items: center;
									justify-content: space-between;
									// background-color: #111215;
									margin-top: 10px;
									.plan_user{
										display: flex;
										align-items: center;
										flex-direction: column;
										.user_img{
											width: 43px;
											height: 43px;
											border-radius: 5px;
											margin-bottom: 10px;
										}
									}
									.plan_menu{
										font-size: 12px;
										display: flex;
										align-items: center;
										flex-direction: column;
										.status{display: flex;align-items: center;justify-content: center;width: 110px; height: 30px;border:1px solid #567BB6;border-radius: 28px;box-sizing: border-box;text-align: center;
											&.active{background-color: #567BB6;color:#fff;border-width: 3px;}
											.date{flex:1;}
										}
										.menu_name{
											margin-top: 10px;
										}
										
									}
									.last_btn{
										display: flex;
										flex-direction: column;
										.el-button{
											margin-left: 0;
											margin-bottom: 10px;
											background-color: #567BB6;
											border: #567BB6;
											color: #fff;
										}
									}
								}
								.center_msg{
									font-size: 10px;
									overflow : hidden;
									  text-overflow: ellipsis;
									  display: -webkit-box;
									  -webkit-line-clamp: 4;
									  -webkit-box-orient: vertical;
								}
							}
						}
					}
				
					.add_work{
						width: 878px;
						background-color: #FFFFFF;
						padding: 50px 30px;
						box-sizing: border-box;
						border-radius: 12px;
						color: #212121;
					}
					.work_center{
						color: #2B2B2A;
						display: flex;
						align-items: center;
						span{
							margin: 10px;
						}
						.el-input{
							width: 100px;
							
						}
					}
				}
				
			}
		}
		
		
		.center_people {
			padding: 10px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0;
			

		}
	}
</style>
